export const EDIT_ACTIONS = {
    SAVE_EXIT: 'save-exit',
    PUBLISH: 'publish',
};

export const CREATED_STATES = {
    ALL: 'all',
    IN_PROGRESS: 'in_progress',
    SCHEDULED: 'pending',
    COMPLETED: 'complete',
    PARTIALLY_COMPLETED: 'partially_completed',
    ABORTED: 'aborted',
};

export const CREATED_STATES_LABELS = {
    [CREATED_STATES.COMPLETED]: 'pmApp.template.status.published',
    [CREATED_STATES.PARTIALLY_COMPLETED]: 'pmApp.template.status.partially_published',
    [CREATED_STATES.IN_PROGRESS]: 'pmApp.social_post.status.saved',
    [CREATED_STATES.SCHEDULED]: 'notification.status.scheduled',
    [CREATED_STATES.ABORTED]: 'pmApp.template.status.aborted',
};

export const NETWORKS_MAP = {
    forFacebook: 'facebook',
    forLinkedin: 'linkedin',
    forTwitter: 'twitter',
} as const;

export const PUBLISH_OPTIONS = {
    NOW: 'now',
    SCHEDULE: 'schedule',
};
