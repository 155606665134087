import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.css';

const getCharsLeft = (value, maxChars, parseValue) => {
    const length = parseValue ? parseValue(value).length : String(value).length;
    return maxChars ? maxChars - length : 9_999;
};

/**
 * Textarea editor
 */
function Textarea({ maxChars, className, singleLine, parseValue, ...props }) {
    const Component = singleLine ? 'input' : 'textarea';
    return (
        <>
            <Component
                {...props}
                className={cn(
                    styles.textarea,
                    singleLine && styles.singleLine,
                    !!maxChars && styles.limitedTextarea,
                    className
                )}
                onChange={e => {
                    const charsLeft = getCharsLeft(e.target.value, maxChars, parseValue);
                    if (charsLeft >= 0) {
                        props.onChange(e);
                    }
                    if (charsLeft < 0) {
                        props.onChange({
                            target: {
                                id: e.target.id,
                                name: e.target.name,
                                value: e.target.value.substr(0, maxChars),
                            },
                        });
                    }
                }}
            />
            {!!maxChars && (
                <div className={styles.charCounter}>
                    <div>{Math.max(0, getCharsLeft(props.value, maxChars, parseValue))}</div>
                </div>
            )}
        </>
    );
}

Textarea.propTypes = {
    /**
     * Max chars limit
     */
    maxChars: PropTypes.number,
    /**
     * Value attr
     */
    value: PropTypes.string,
    /**
     * Class name
     */
    className: PropTypes.string,
    /**
     * Use single line style
     */
    singleLine: PropTypes.bool,
    /**
     * Function that counts characters and validates message after parsing
     * @see twitter.parseTweet from 'twitter-text'
     * left it undefined to use str.length
     */
    parseValue: PropTypes.func,
    /**
     * On change handler
     */
    onChange: PropTypes.func.isRequired,
};

export default Textarea;
