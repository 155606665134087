import React, { lazy, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { APPS, ROUTE } from 'constants.js';
import { Alert, Footer, Header, TranslateJiraHelpdeskWidget } from 'components';
import layoutContext from 'context/layoutContext';
import { getCurrentUser } from 'selectors/userSelectors';
import SubApp from 'apps/SubApp';

const AccountSettings = lazy(() => import('./pages/user/AccountSettings/AccountSettings'));
const Spiff = lazy(() => import('./pages/spiff/Spiff'));
const Help = lazy(() => import('./pages/help/Help'));
const Manage = lazy(() => import('./pages/manage/Manage'));
const Asset = lazy(() => import('./pages/asset/Asset'));
const AssetLibrary = lazy(() => import('./pages/asset/library/Library'));
const Lead = lazy(() => import('./pages/lead/Lead'));
const CreateCampaignInstance = lazy(() => import('./pages/campaignInstance/create/Create'));
const ManageCampaigns = lazy(() => import('./pages/manage/Campaigns/List/List'));
const Report = lazy(() => import('./pages/report/Report'));
const Basket = lazy(() => import('./pages/user/Basket/Basket'));
const Activity = lazy(() => import('./pages/activity/Activity'));
const CampaignInstance = lazy(() => import('./pages/campaignInstance/CampaignInstance'));
const MarketingStore = lazy(() => import('./pages/marketingStore/MarketingStore'));
const LoyaltyProgram = lazy(() => import('./pages/loyaltyProgram/LoyaltyProgram'));
const SalesResources = lazy(() => import('./pages/salesResources/SalesResources'));
const Onboarding = lazy(() => import('./pages/onboarding/Onboarding'));
const PartnerLead = lazy(() => import('./pages/partnerLead/PartnerLead'));
const Incentive = lazy(() => import('./pages/incentive/Incentive'));
const Opportunity = lazy(() => import('./pages/opportunity/Opportunity'));
const OpportunityLead = lazy(() => import('./pages/opportunityLead/OpportunityLead'));
const SocialPosts = lazy(() => import('./pages/socialPosts/SocialPosts'));

const App = () => {
    const [extended, setExtended] = useState(false);
    const user = useSelector(getCurrentUser);
    if (!user?.id) {
        return null;
    }
    return (
        <layoutContext.Provider value={{ extended, setExtended }}>
            <Header />
            <Alert />

            <div className="root-content">
                <Switch>
                    <Route path={ROUTE.account.root} component={AccountSettings} />
                    <Route path={ROUTE.basket.root} component={Basket} />
                    <Route path={ROUTE.salesResources.root} component={SalesResources} />
                    <Route path={ROUTE.spiff.root} component={Spiff} />
                    <Route path={ROUTE.manageCampaigns()} component={ManageCampaigns} />
                    <Route path={ROUTE.manage.root} component={Manage} />
                    <Route path={ROUTE.userEdit()} component={Manage} />
                    <Route path={ROUTE.manageTemplates.root} component={Manage} />
                    <Route path={ROUTE.asset.root} component={Asset} />
                    <Route path={ROUTE.assetLibrary.root} component={AssetLibrary} />
                    <Route path={ROUTE.lead.root} component={Lead} />
                    <Route path={ROUTE.leads.root} component={Lead} />
                    <Route path={ROUTE.activities.root} component={Activity} />
                    <Route path={ROUTE.reporting.root} component={Report} />
                    <Route path={ROUTE.campaignInstances.root} component={CampaignInstance} />
                    <Route path={ROUTE.createCampaign.root} component={CreateCampaignInstance} />
                    <Route path={ROUTE.campaignDetails.root} component={CreateCampaignInstance} />
                    <Route path={ROUTE.thenCreateCampaign.root} component={CreateCampaignInstance} />
                    <Route path={ROUTE.marketingStore.root} component={MarketingStore} />
                    <Route path={ROUTE.campaign.root} component={CampaignInstance} />
                    <Route path={ROUTE.loyaltyProgram.root} component={LoyaltyProgram} />
                    <Route path={ROUTE.onboarding.root} component={Onboarding} />
                    <Route path={ROUTE.partnerLead.root} component={PartnerLead} />
                    <Route path={ROUTE.incentive.root} component={Incentive} />
                    <Route path={ROUTE.opportunity.root} component={Opportunity} />
                    <Route path={ROUTE.opportunities.root} component={OpportunityLead} />
                    <Route path={ROUTE.socialPosts.root} component={SocialPosts} />
                    <SubApp path={ROUTE.templateBuilder.root} name={APPS.TEMPLATE_BUILDER} />
                    <Route path="/dev-help" component={Help} />
                </Switch>
            </div>
            <Footer />
            <TranslateJiraHelpdeskWidget />
            <ReactTooltip place="top" type="dark" effect="solid" multiline className="react-tooltip" />
        </layoutContext.Provider>
    );
};

export default App;
