import React from 'react';
import { t } from 'utils/lang';

function translateHowCanWeHelp(reactRoot) {
    const element = reactRoot.querySelector('[data-ds--text-field--input="true"]:not([data-translated="true"])');
    if (element) {
        if (element && element.placeholder === 'How can we help?') {
            element.placeholder = t('jira.widget.how_can_we_help');
            element.setAttribute('data-translated', 'true');
        }
    }
}

function translateLabel(reactRoot, id, label, translation) {
    const element = reactRoot.querySelector(`label[for=${id}]:not([data-translated="true"])`);
    if (element && element.innerHTML.search(label) > -1) {
        element.innerHTML = element.innerHTML.replace(label, translation);
        element.setAttribute('data-translated', 'true');
    }
}

function translateElementsByTagName(reactRoot, tagName, search, translation) {
    const elements = reactRoot.querySelectorAll(`${tagName}:not([data-translated="true"])`);
    for (let i = 0; i < elements.length; i++) {
        if (elements[i].textContent === search) {
            elements[i].textContent = translation;
            elements[i].setAttribute('data-translated', 'true');
        }
    }
}

function translateTexts(reactRoot) {
    translateHowCanWeHelp(reactRoot);
    translateLabel(reactRoot, 'email', 'Your contact e-mail', t('jira.widget.your_contact_email'));
    translateLabel(reactRoot, 'description', 'Body', t('jira.widget.description'));
    translateLabel(reactRoot, 'description', 'How can we help you?', t('jira.widget.description'));
    translateLabel(reactRoot, 'summary', 'Subject', t('jira.widget.summary'));
    translateLabel(reactRoot, 'summary', 'Summary', t('jira.widget.summary'));
    translateLabel(reactRoot, 'attachment', 'Attachment', t('jira.widget.attachment'));
    translateElementsByTagName(reactRoot, 'p', 'File size should not exceed 5 MB', t('jira.widget.file_size'));
    translateElementsByTagName(reactRoot, 'h2', 'Contact us about', t('jira.widget.contact_us_about'));
    translateElementsByTagName(reactRoot, 'h2', 'Learn about', t('jira.widget.learn_about'));
    translateElementsByTagName(reactRoot, 'h3', 'Thanks!', t('jira.widget.message_sent_thanks'));
    translateElementsByTagName(
        reactRoot,
        'p',
        "We've received your request and will get back to you shortly.",
        t('jira.widget.message_sent_description')
    );
    translateElementsByTagName(reactRoot, 'p', 'Emailed request', t('jira.widget.email_request'));
    translateElementsByTagName(
        reactRoot,
        'p',
        'Request received from your email support channel.',
        t('jira.widget.email_request_description')
    );
    translateElementsByTagName(reactRoot, 'button', 'Send', t('jira.widget.submit_button_send'));
}

function useJiraWidgetTranslate() {
    React.useEffect(() => {
        const observeIframe = iframe => {
            if (!iframe) return;
            const watchContentChange = () => {
                const w = iframe.contentWindow;
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const reactRoot = iframeDoc.querySelector('#react-root');

                // Create a new observer for reactRoot
                const observer = new w.MutationObserver(mutations => {
                    mutations.forEach(mutation => {
                        if (mutation.type === 'childList') {
                            translateTexts(reactRoot);
                        }
                    });
                });
                // Init the new observer
                observer.observe(reactRoot, { childList: true, subtree: true });
            };
            if (iframe.contentDocument || iframe.contentWindow?.document) {
                watchContentChange();
            } else {
                // eslint-disable-next-line no-param-reassign
                iframe.onload = watchContentChange;
            }
        };

        function addListenerForIframe() {
            const observer = new MutationObserver(mutations => {
                let found = false;
                mutations.forEach(mutation => {
                    if (found) return;
                    if (mutation.type === 'childList') {
                        // Check if the iframe has been added
                        const iframe = document.querySelector('#jsd-widget');
                        if (iframe) {
                            found = true;
                            observeIframe(iframe);
                            observer.disconnect();
                        }
                    }
                });
            });
            observer.observe(document.body, { childList: true, subtree: true });
            window.setTimeout(() => {
                observer.disconnect();
            }, 5000); // Something wierd happends. Lets still clean it up to spead up browser
        }
        addListenerForIframe();
    }, []);
}

export default function TranslateJiraHelpdeskWidget() {
    useJiraWidgetTranslate();
}
