import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { CREATED_STATES } from 'pages/socialPosts/constants';
import { MODULES, ROUTE } from '../../../../constants';
import { t } from '../../../../utils';
import { Grouping, Link, Separator, DropDownLink, DropDownSection, Columns, SideMenu } from './MenuElements';
import { CAMPAIGNS_STATUS } from '../../../../pages/manage/manageConstants';
import styles from '../../Header.module.css';
import { getOpportunityModuleSettings } from '../../../../selectors/userSelectors';
import setUpMenuItems from './SetUpMenuItems';

/**
 * Helper function for Menu
 * @ignore
 */
export const getAdminItems = args => {
    const {
        modules,
        isClientLoyaltyProgramEnabled,
        loyaltyClubs,
        hasFullTemplateBuilderAccess,
        isSocialMediaPostsEnabled,
        campaignBuilderSettings: { isAssetRequestEnabled, isBespokeAssetRequestEnabled },
    } = args;

    return (
        <>
            <DropDownLink title={t('client._global_admin_set_up_dropdown.title')} key="Admin">
                <DropDownSection
                    className={styles.widSection}
                    title={t('client._general_dropdown.section_title.site_settings')}
                    description={t('client._general_dropdown.section_description.site_settings')}
                >
                    <Separator />
                    <Columns size={3}>
                        <ul>
                            <Link title={t('module_settings.title')} to={ROUTE.moduleSettings} />
                            <Link title={t('client._vendor_set_up_dropdown.regions')} to={ROUTE.regions} />
                            <Link title={t('client._vendor_set_up_dropdown.tiers')} to={ROUTE.tiers} />
                            <Link title={t('client._vendor_set_up_dropdown.permissions')} to={ROUTE.permissions} />
                        </ul>
                        <ul>
                            <Link
                                title={t('client._vendor_set_up_dropdown.settings')}
                                to={ROUTE.campaignBuilderSetup.groups}
                            />
                            <Link title={t('survey.nps_survey')} to={ROUTE.manage.survey.list} />
                            <Link
                                title={t('client._designer_set_up_dropdown.featured_campaigns')}
                                to={ROUTE.manage.featuredCampaigns.list}
                                visible={modules[MODULES.MARKETING_STORE] || modules[MODULES.CAMPAIGN_BUILDER]}
                            />
                            <Link
                                title={t('manage.template_types.manage')}
                                to={ROUTE.manage.templateTypes.list}
                                visible={modules[MODULES.CAMPAIGN_BUILDER]}
                            />
                            <Link title={t('client._vendor_set_up_dropdown.notifications')} to={ROUTE.notifications} />
                        </ul>
                        <ul>
                            <Link
                                title={t('approval_group.title')}
                                to={ROUTE.approvalGroups}
                                visible={modules[MODULES.SPIFF]}
                            />
                            <Link
                                title={t('client._vendor_set_up_dropdown.product_specialisms')}
                                to={ROUTE.productSpecialisms}
                                visible={modules[MODULES.IDENTIFIER_BUILDER]}
                            />
                            <Link
                                title={t('client._vendor_set_up_dropdown.service_specialisms')}
                                to={ROUTE.serviceSpecialisms}
                                visible={modules[MODULES.IDENTIFIER_BUILDER]}
                            />
                        </ul>
                    </Columns>
                </DropDownSection>

                <div className={styles.flexRow}>
                    <DropDownSection
                        title={t('client._general_dropdown.section_title.users')}
                        description={t('client._general_dropdown.section_description.users')}
                    >
                        <Separator />
                        <Link
                            title={t('client._vendor_set_up_dropdown.active_users')}
                            to={ROUTE.manage.users.list('active')}
                        />
                        <Link
                            title={t('client._vendor_set_up_dropdown.pending_users')}
                            to={ROUTE.manage.users.list('pending')}
                        />
                        <Link
                            title={t('client._vendor_set_up_dropdown.awaiting_users')}
                            to={ROUTE.manage.users.list('unapproved')}
                        />
                        <Link
                            title={t('client._vendor_set_up_dropdown.rejected_users')}
                            to={ROUTE.manage.users.list('rejected')}
                        />
                        <Link
                            title={t('client._vendor_set_up_dropdown.inactive_users')}
                            to={ROUTE.manage.users.list('inactive')}
                        />
                        <Link
                            title={t('client._vendor_set_up_dropdown.deleted_users')}
                            to={ROUTE.manage.users.list('deleted')}
                        />
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.companies')}
                        description={t('client._general_dropdown.section_description.companies')}
                    >
                        <Separator />
                        <Link title={t('client._global_admin_set_up_dropdown.manage_companies')} to={ROUTE.companies} />
                        <Link
                            title={t('client._global_admin_set_up_dropdown.merge_companies')}
                            to={ROUTE.mergeCompanies}
                        />
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.marketing_campaigns_and_assets')}
                        description={t('client._general_dropdown.section_description.marketing_campaigns_and_assets')}
                    >
                        <Separator />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_campaigns')}
                            to={ROUTE.manageCampaigns(CAMPAIGNS_STATUS.ACTIVE)}
                            visible={modules[MODULES.MARKETING_STORE] || modules[MODULES.CAMPAIGN_BUILDER]}
                        />
                        <Link
                            title={t('client._global_admin_set_up_dropdown.manage_co_branded_assets')}
                            to={ROUTE.manageTemplates.list}
                        />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_toggle_groups')}
                            to={ROUTE.manage.toggleGroups.listStatus('active')}
                        />
                        <Separator visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess} />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_colour_groups')}
                            to={ROUTE.templateBuilder.colourGroups.list()}
                            visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess}
                        />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_cta_styles')}
                            to={ROUTE.templateBuilder.buttons.list()}
                            visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess}
                        />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_footers')}
                            to={ROUTE.templateBuilder.footers.list()}
                            visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess}
                        />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_forms')}
                            to={ROUTE.templateBuilder.forms.list()}
                            visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess}
                        />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_templates')}
                            to={ROUTE.templateBuilder.templates.list()}
                            visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess}
                        />
                        <Link
                            title={t('client._designer_set_up_dropdown.manage_test_styles')}
                            to={ROUTE.templateBuilder.textStyles.list()}
                            visible={modules[MODULES.CAMPAIGN_BUILDER] && hasFullTemplateBuilderAccess}
                        />
                    </DropDownSection>
                </div>

                <div className={styles.flexRow}>
                    <DropDownSection
                        title={t('client._general_dropdown.section_title.managed_marketing_activities')}
                        description={t('client._general_dropdown.section_description.managed_marketing_activities')}
                        visible={modules[MODULES.MARKETING_STORE]}
                    >
                        <Separator />
                        <Link
                            title={t('client._set_up_dropdown.marketing_activities_manage')}
                            to={ROUTE.marketingStoreSetUpActivities}
                        />
                        <Link
                            title={t('client._set_up_dropdown.marketing_activities_mangage_groups')}
                            to={ROUTE.marketingStoreSetUpActivityGroups}
                        />
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.resources')}
                        description={t('client._general_dropdown.section_description.resources')}
                        visible={modules[MODULES.ASSET_LIBRARY]}
                    >
                        <Separator />
                        <Link
                            title={t('client._global_admin_set_up_dropdown.manage_resources')}
                            to={ROUTE.assetLibrarySetUpListAssets}
                        />
                        <Link
                            title={t('client._global_admin_set_up_dropdown.manage_resource_types')}
                            to={ROUTE.assetLibrarySetUpTypes}
                        />
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.loyalty_program')}
                        description={t('client._general_dropdown.section_description.loyalty_program')}
                        visible={modules[MODULES.LOYALTY_PROGRAM]}
                    >
                        <Separator />
                        <Link
                            title={t('client._vendor_set_up_dropdown.manage_banners')}
                            to={ROUTE.manage.loyaltyBanners.listStatus('active')}
                            visible={isClientLoyaltyProgramEnabled}
                        />
                        <Link
                            title={t('client._vendor_set_up_dropdown.manage_master_rewards')}
                            to={ROUTE.manage.loyaltyRewards.list}
                            visible={isClientLoyaltyProgramEnabled}
                        />
                        {!!loyaltyClubs.length &&
                            loyaltyClubs.map(club => (
                                <Link
                                    key={club}
                                    title={t('client._vendor_set_up_dropdown.manage_club_rewards', {
                                        name: club.toUpperCase(),
                                    })}
                                    to={ROUTE.manage.loyaltyRewardInstances.listStatus(club, 'visible')}
                                    visible={isClientLoyaltyProgramEnabled}
                                />
                            ))}
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.social_media_posts')}
                        description={t('client._general_dropdown.section_description.social_media_posts')}
                        visible={isSocialMediaPostsEnabled}
                    >
                        <Separator />
                        <Link
                            title={t('client._vendor_set_up_dropdown.manage_social_posts')}
                            to={ROUTE.manage.socialPosts.listStatus('active')}
                        />
                    </DropDownSection>
                </div>

                <div className={styles.flexRow}>
                    <DropDownSection
                        title={t('client._general_dropdown.section_title.manage_requests')}
                        description={t('client._general_dropdown.section_description.manage_requests')}
                        visible={isAssetRequestEnabled || isBespokeAssetRequestEnabled}
                    >
                        <Separator />
                        <Link
                            title={t('client._designer_set_up_dropdown.asset_requests')}
                            to={ROUTE.manage.assetRequests}
                        />
                    </DropDownSection>
                </div>
            </DropDownLink>
            <Link
                title={t('client.create_edit.spiff.title')}
                to={ROUTE.spiff.applicationsRoot}
                key="spiff"
                visible={args.modules[MODULES.SPIFF]}
                topLevel
            />
        </>
    );
};

const getPartnerDashboardItems = () => (
    <Link title={t('base._client_logged_in_header.home')} to={ROUTE.home} key="PartnerDashboard" topLevel />
);

const getDropdownLinkTitle = args => {
    const { modules, marketingStoreSettings } = args;
    // Set campaigns and assets navigation title dependant on what is enabled
    const marketingStore = modules[MODULES.MARKETING_STORE];
    const campaignBuilder = modules[MODULES.CAMPAIGN_BUILDER];
    const assetLibrary = modules[MODULES.ASSET_LIBRARY];
    let dropdownLinkTitle = '';

    if (!marketingStore && !campaignBuilder && !assetLibrary) {
        return null;
    }

    // Menu items text are depends on enabled modules.
    if ((campaignBuilder || marketingStoreSettings.campaignsEnabled) && marketingStore) {
        // No matter enabled assetLibrary or not.
        dropdownLinkTitle = t('navigation_links.campaigns_and_activities');
    } else if (marketingStore && assetLibrary) {
        dropdownLinkTitle = t('navigation_links.activities_and_assets');
    } else if (campaignBuilder && assetLibrary) {
        dropdownLinkTitle = t('navigation_links.campaigns_and_assets');
    } else if (campaignBuilder) {
        dropdownLinkTitle = t('navigation_links.campaigns');
    } else if (marketingStore) {
        dropdownLinkTitle = t('navigation_links.marketing_activities');
    } else if (assetLibrary) {
        dropdownLinkTitle = t('navigation_links.assets');
    } else {
        throw new Error('Unknown combination');
    }

    return dropdownLinkTitle;
};

const getSocialPostsSection = (isSocialMediaPostsEnabled, skipListItem = false) => (
    <DropDownSection
        title={t('client._general_dropdown.section_title.social_media_posts')}
        description={t('client._general_dropdown.section_description.social_media_posts_create')}
        visible={isSocialMediaPostsEnabled}
    >
        {!skipListItem && (
            <>
                <Separator />
                <Link title={t('client._campaigns_dropdown.social_post_list')} to={ROUTE.socialPosts.create} />
            </>
        )}
        <Separator />
        <Link
            title={t('client._campaigns_dropdown.social_post_saved')}
            to={ROUTE.socialPosts.byState(CREATED_STATES.IN_PROGRESS)}
        />
        <Link
            title={t('client._campaigns_dropdown.social_post_scheduled')}
            to={ROUTE.socialPosts.byState(CREATED_STATES.SCHEDULED)}
        />
        <Link
            title={t('client._campaigns_dropdown.social_post_completed')}
            to={ROUTE.socialPosts.byState(CREATED_STATES.COMPLETED)}
        />
    </DropDownSection>
);

const getCampaignsDropdownItems = args => {
    const { modules, campaignBuilderSettings, isSocialMediaPostsEnabled } = args;
    // Set campaigns and assets navigation title dependant on what is enabled
    const marketingStore = modules[MODULES.MARKETING_STORE];
    const campaignBuilder = modules[MODULES.CAMPAIGN_BUILDER];
    const assetLibrary = modules[MODULES.ASSET_LIBRARY];

    const isAssetRequestEnabled =
        campaignBuilderSettings &&
        (campaignBuilderSettings.isAssetRequestEnabled || campaignBuilderSettings.isBespokeAssetRequestEnabled);
    const dropdownLinkTitle = getDropdownLinkTitle(args);

    if (!marketingStore && !campaignBuilder && !assetLibrary) {
        return null;
    }

    return (
        <DropDownLink title={dropdownLinkTitle} key="CampaignsDropdown">
            <DropDownSection
                title={t('client._general_dropdown.section_title.managed_marketing_activities')}
                description={t('client._general_dropdown.section_description.managed_marketing_activities_4')}
                visible={marketingStore}
            >
                <Separator />
                <Link title={t('client._vendor_reports_dropdown.view_all_activities')} to={ROUTE.marketingStore.root} />
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.requested_activities')}
                    to={ROUTE.activities.byState('requested')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.in_planning_activities')}
                    to={ROUTE.activities.byState('in_planning')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.in_progress_activities')}
                    to={ROUTE.activities.byState('in_progress')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.completed_activities_and_reports')}
                    to={ROUTE.activities.byState('completed')}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.marketing_campaigns_and_assets')}
                description={t('client._general_dropdown.section_description.marketing_campaigns_and_assets_4')}
                visible={campaignBuilder}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.view_by_campaign')}
                    to={ROUTE.createCampaign.byView('campaigns')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.view_by_asset')}
                    to={ROUTE.createCampaign.byView('assets')}
                    visible={campaignBuilderSettings.isAssetViewEnabled}
                />
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.drafted_assets')}
                    to={ROUTE.campaignInstances.byState('drafted')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.in_progress_assets')}
                    to={ROUTE.campaignInstances.byState('in_progress')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.completed_assets_and_reports')}
                    to={ROUTE.campaignInstances.byState('complete')}
                />
                <Separator visible={isAssetRequestEnabled} />
                <Link
                    title={t('client._vendor_reports_dropdown.asset_requests')}
                    to={ROUTE.asset.request.myRequests}
                    visible={isAssetRequestEnabled}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.resources')}
                description={t('client._general_dropdown.section_description.resources_3')}
                visible={assetLibrary}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.view_all_resources')}
                    to={ROUTE.assetLibrary.browse.list}
                />
                {args.resourceTypes.map(type => {
                    return (
                        <Link
                            key={`asset_library_resource_type_menu_${type.id}`}
                            title={t('client._asset_library_dropdown.resource_item', { name: type.name })}
                            to={ROUTE.assetLibrary.browse.listByType(type.name)}
                        />
                    );
                })}
            </DropDownSection>

            {getSocialPostsSection(isSocialMediaPostsEnabled)}
        </DropDownLink>
    );
};

const getLeadsDropdownItems = args => {
    const { modules, roles } = args;
    if (!modules[MODULES.CAMPAIGN_BUILDER] && !modules[MODULES.OPPORTUNITY_MANAGEMENT]) {
        return null;
    }

    const { isFlowDedicated: canViewDedicatedOpportunities, isFlowStandard: canViewStandardOpportunities } =
        useSelector(getOpportunityModuleSettings) || {};

    return (
        <DropDownLink title={t('client._leads_dropdown.leads')} key="LeadsDropdown">
            {roles.isConciergeInternal && (
                <DropDownSection
                    title={t('client._general_dropdown.section_title.data')}
                    description={t('client._general_dropdown.section_description.data')}
                    visible={modules[MODULES.CAMPAIGN_BUILDER]}
                >
                    <Separator />
                    <Link title={t('client._leads_dropdown.manage_my_data_lists')} to={ROUTE.lead.list.root} />
                    <Separator visible={modules[MODULES.OPPORTUNITY_MANAGEMENT]} />
                    {canViewDedicatedOpportunities && (
                        <Link title={t('client._leads_dropdown.register_new_lead')} to={ROUTE.partnerLead.create} />
                    )}
                </DropDownSection>
            )}
            {!roles.isConciergeInternal && (
                <>
                    <DropDownSection
                        title={t('client._general_dropdown.section_title.data')}
                        description={t('client._general_dropdown.section_description.data')}
                        visible={modules[MODULES.CAMPAIGN_BUILDER]}
                    >
                        <Separator />
                        <Link title={t('client._leads_dropdown.upload_new_data')} to={ROUTE.lead.list.create.root} />
                        <Link title={t('client._leads_dropdown.manage_my_existing_data')} to={ROUTE.leads.root} />
                        <Link title={t('client._leads_dropdown.manage_my_data_lists')} to={ROUTE.lead.list.root} />
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.leads')}
                        description={t('client._general_dropdown.section_description.leads')}
                        visible={modules[MODULES.CAMPAIGN_BUILDER]}
                    >
                        <Separator />
                        <Link title={t('client._leads_dropdown.open_leads')} to={ROUTE.lead.list.myLeads.root} />
                        <Link
                            title={t('client._leads_dropdown.archived_leads')}
                            to={ROUTE.lead.list.myLeads.byStatus('archived')}
                        />
                    </DropDownSection>

                    <DropDownSection
                        title={t('client._general_dropdown.section_title.opportunities')}
                        description={t('client._general_dropdown.section_description.opportunities')}
                        visible={modules[MODULES.OPPORTUNITY_MANAGEMENT]}
                    >
                        <Separator visible={canViewDedicatedOpportunities || canViewStandardOpportunities} />
                        <Link
                            title={t('client._leads_dropdown.my_opportunities')}
                            to={ROUTE.opportunity.root}
                            visible={canViewDedicatedOpportunities}
                        />
                        <Link
                            title={t('client._leads_dropdown.register_new_lead')}
                            to={ROUTE.partnerLead.create}
                            visible={canViewDedicatedOpportunities}
                        />
                        <Link
                            title={t('client._leads_dropdown.my_opportunities')}
                            to={ROUTE.opportunities.list}
                            visible={canViewStandardOpportunities}
                        />
                        <Link
                            title={t('client._leads_dropdown.register_a_new_opportunity')}
                            to={ROUTE.opportunities.register}
                            visible={canViewStandardOpportunities}
                        />
                    </DropDownSection>
                </>
            )}
        </DropDownLink>
    );
};

const getActivityReportsDropdownItems = args => {
    const { modules, isSocialMediaPostsEnabled } = args;
    if (!modules[MODULES.CAMPAIGN_BUILDER] && !modules[MODULES.MARKETING_STORE] && !isSocialMediaPostsEnabled) {
        return null;
    }

    return (
        <DropDownLink title={t('client._activity_reports_dropdown.activity_and_reports')} key="ActivityReportsDropdown">
            <DropDownSection
                title={t('client._general_dropdown.section_title.managed_marketing_activities')}
                description={t('client._general_dropdown.section_description.managed_marketing_activities_3')}
                visible={modules[MODULES.MARKETING_STORE]}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.requested_activities')}
                    to={ROUTE.activities.byState('requested')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.in_planning_activities')}
                    to={ROUTE.activities.byState('in_planning')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.in_progress_activities')}
                    to={ROUTE.activities.byState('in_progress')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.completed_activities_and_reports')}
                    to={ROUTE.activities.byState('completed')}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.marketing_campaigns_and_assets')}
                description={t('client._general_dropdown.section_description.marketing_campaigns_and_assets_3')}
                visible={modules[MODULES.CAMPAIGN_BUILDER]}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.drafted_assets')}
                    to={ROUTE.campaignInstances.byState('drafted')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.in_progress_assets')}
                    to={ROUTE.campaignInstances.byState('in_progress')}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.completed_assets_and_reports')}
                    to={ROUTE.campaignInstances.byState('complete')}
                />
            </DropDownSection>

            {getSocialPostsSection(isSocialMediaPostsEnabled, true)}
        </DropDownLink>
    );
};

const getLoyaltyProgramDropdownItems = args => {
    const { modules, rewardClubPrograms } = args;

    if (!modules[MODULES.LOYALTY_PROGRAM] || Object.keys(rewardClubPrograms).length === 0) {
        return null;
    }

    return (
        <DropDownLink title={t('loyalty_program.menu.rewards_club')} key="ActivityReportsDropdown" isWide>
            {Object.keys(rewardClubPrograms).map((program, index) => (
                <>
                    {index > 0 && <Separator />}
                    {program === 'wep' && <Grouping title={t('loyalty_program.menu.wep_grouping')} />}
                    {program !== 'wep' && <Grouping title={t('loyalty_program.menu.lcsc_grouping')} />}
                    <Link
                        title={t('loyalty_program.menu.sign_up')}
                        to={ROUTE.loyaltyProgram.signUp(program)}
                        visible={!rewardClubPrograms[program].signed}
                    />
                    <Link
                        title={t('loyalty_program.menu.reward_club_overview')}
                        to={ROUTE.loyaltyProgram.viewProgram(program)}
                        visible={rewardClubPrograms[program].signed}
                    />
                    <Link
                        title={t('loyalty_program.menu.earn_points')}
                        to={ROUTE.loyaltyProgram.howToEarn(program)}
                        visible={rewardClubPrograms[program].signed}
                    />
                    <Link
                        title={t('loyalty_program.menu.spend_points')}
                        to={ROUTE.loyaltyProgram.catalogueRoot(program)}
                        visible={rewardClubPrograms[program].signed}
                    />
                    <Link
                        title={t('loyalty_program.menu.manage_points')}
                        to={ROUTE.loyaltyProgram.statement.root(program)}
                        visible={rewardClubPrograms[program].signed && rewardClubPrograms[program].isAdmin}
                    />
                </>
            ))}
        </DropDownLink>
    );
};

/**
 * Helper function for Menu
 * @ignore
 */
export const getPartnerItems = args => [
    getPartnerDashboardItems(),
    getCampaignsDropdownItems(args),
    <Link
        title={t('client.create_edit.sales_resources')}
        to={ROUTE.salesResources.root}
        key="sales_resources"
        visible={args.modules[MODULES.SALES_RESOURCES]}
        topLevel
    />,
    getLeadsDropdownItems(args),
    <Link
        title={t('identifier_builder.title')}
        to={ROUTE.identifierBuilder}
        visible={args.modules[MODULES.IDENTIFIER_BUILDER]}
        key="identifier_builder"
        topLevel
    />,
    getActivityReportsDropdownItems(args),
    getLoyaltyProgramDropdownItems(args),
    <Link
        title={t('client.create_edit.spiff.title')}
        to={ROUTE.spiff.application.status}
        key="spiff"
        visible={args.modules[MODULES.SPIFF]}
        topLevel
    />,
    <Link
        title={t('dashboard.versapay.incentive.menu')}
        to={ROUTE.incentive.root}
        key="incentive"
        visible={args.modules[MODULES.INCENTIVE]}
        topLevel
    />,
];

const getDesignerSetUpDropdown = args => (
    <DropDownLink title={t('client._designer_set_up_dropdown.title')} key="DesignerSetUpDropdown2" noPadding>
        <SideMenu rows={setUpMenuItems.getSetUpMenuItems(args)} />
    </DropDownLink>
);

/**
 * Helper function for Menu
 * @ignore
 */
export const getDesignerItems = args => [
    getPartnerDashboardItems(),
    getCampaignsDropdownItems(args),
    <Link
        title={t('client.create_edit.sales_resources')}
        to={ROUTE.salesResources.root}
        key="sales_resources"
        visible={args.modules[MODULES.SALES_RESOURCES]}
        topLevel
    />,
    getLeadsDropdownItems(args),
    <Link
        title={t('identifier_builder.title')}
        to={ROUTE.identifierBuilder}
        visible={args.modules[MODULES.IDENTIFIER_BUILDER]}
        key="identifier_builder"
        topLevel
    />,
    getActivityReportsDropdownItems(args),
    getDesignerSetUpDropdown(args),
];

const getVendorReportsDropdown = args => {
    const { modules } = args;
    const { isFlowStandard: canViewOpportunityReport } = useSelector(getOpportunityModuleSettings) || {};

    return (
        <DropDownLink title={t('client._vendor_reports_dropdown.reports')} key="VendorReportsDropdown">
            <DropDownSection
                title={t('client._general_dropdown.section_title.general_reports')}
                description={t('client._general_dropdown.section_description.general_reports')}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.users_report')}
                    to={ROUTE.reporting.vendorPartnersReport}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.opportunities_report')}
                    to={ROUTE.reporting.vendor.opportunityLeads}
                    visible={canViewOpportunityReport}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.marketing_campaigns_and_assets')}
                description={t('client._general_dropdown.section_description.marketing_campaigns_and_assets_2')}
                visible={modules[MODULES.CAMPAIGN_BUILDER]}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.participation_report')}
                    to={ROUTE.reporting.vendorPartnersParticipationReport}
                />
                <Link
                    title={t('client._vendor_reports_dropdown.assets_report')}
                    to={ROUTE.reporting.vendorCampaignsReport}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.managed_marketing_activities')}
                description={t('client._general_dropdown.section_description.managed_marketing_activities_2')}
                visible={modules[MODULES.MARKETING_STORE]}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.marketing_activities_report')}
                    to={ROUTE.reporting.marketingStore.root}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.resources')}
                description={t('client._general_dropdown.section_description.resources_2')}
                visible={modules[MODULES.ASSET_LIBRARY]}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.resources_report')}
                    to={ROUTE.assetLibraryReportingOverview}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.identifier_and_certificate_builder')}
                description={t('client._general_dropdown.section_description.identifier_and_certificate_builder')}
                visible={modules[MODULES.IDENTIFIER_BUILDER]}
            >
                <Separator />
                <Link title={t('identifier_builder.report.title')} to={ROUTE.identifierBuilderReports} />
                <Link
                    title={t('client._vendor_reports_dropdown.certificate_builder_report')}
                    to={ROUTE.certificateBuilderReports}
                />
            </DropDownSection>
        </DropDownLink>
    );
};

/**
 * Helper function for Menu
 * @ignore
 */
export const getReportManagerItems = args => [
    <Link title={t('base._client_logged_in_header.home')} to={ROUTE.vendorDashboard} key="vendor_dashboard" topLevel />,
    getVendorReportsDropdown(args),
];

const getConciergeSetUpDropdown = args => {
    const { modules } = args;

    return (
        <DropDownLink title={t('client._vendor_set_up_dropdown.title')} key="ConciergeSetUpDropdown">
            <DropDownSection
                title={t('client._general_dropdown.section_title.general_settings')}
                description={t('client._general_dropdown.section_description.general_settings')}
                visible={modules[MODULES.MARKETING_STORE]}
            >
                <Separator />
                <Link title={t('client._vendor_set_up_dropdown.manage_users')} to={ROUTE.users.root} />
                <Link
                    title={t('client._set_up_dropdown.marketing_activities_manage')}
                    to={ROUTE.marketingStoreSetUpActivities}
                    visible={modules[MODULES.MARKETING_STORE]}
                />
                <Link title={t('client._concierge_set_up_dropdown.manage_reports')} to={ROUTE.reporting.root} />
            </DropDownSection>
        </DropDownLink>
    );
};

/**
 * Helper function for Menu
 * @ignore
 */
export const getConciergeItems = args => [getConciergeSetUpDropdown(args)];

const getVendorSetUpDropdown = args => {
    const { modules, isSSOEnabled, isSSOUserRegistrationEnabled, hasUserApprovalPermission } = args;

    return (
        <DropDownLink title={t('client._vendor_set_up_dropdown.title')} key="VendorSetUpDropdown">
            <DropDownSection
                title={t('client._general_dropdown.section_title.users')}
                description={t('client._general_dropdown.section_description.users')}
            >
                <Separator />
                <Link title={t('client._vendor_set_up_dropdown.active_users')} to={ROUTE.manage.users.list('active')} />
                <Link
                    title={t('client._vendor_set_up_dropdown.pending_users')}
                    to={ROUTE.manage.users.list('pending')}
                />
                {(!isSSOEnabled || isSSOUserRegistrationEnabled || hasUserApprovalPermission) && (
                    <Link
                        title={t('client._vendor_set_up_dropdown.awaiting_users')}
                        to={ROUTE.manage.users.list('unapproved')}
                    />
                )}
                <Link
                    title={t('client._vendor_set_up_dropdown.inactive_users')}
                    to={ROUTE.manage.users.list('inactive')}
                />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.site_settings')}
                description={t('client._general_dropdown.section_description.site_settings_2')}
            >
                <Separator />
                <Link title={t('client._vendor_set_up_dropdown.notifications')} to={ROUTE.notifications} />
                <Link title={t('client._vendor_set_up_dropdown.settings')} to={ROUTE.campaignBuilderSetup.groups} />
            </DropDownSection>

            <DropDownSection
                title={t('client._general_dropdown.section_title.resources')}
                description={t('client._general_dropdown.section_description.resources')}
                visible={modules[MODULES.ASSET_LIBRARY]}
            >
                <Separator />
                <Link
                    title={t('client._global_admin_set_up_dropdown.manage_resources')}
                    to={ROUTE.assetLibrarySetUpListAssets}
                />
                <Link
                    title={t('client._global_admin_set_up_dropdown.manage_resource_types')}
                    to={ROUTE.assetLibrarySetUpTypes}
                />
            </DropDownSection>
        </DropDownLink>
    );
};

/**
 * Helper function for Menu
 * @ignore
 */
export const getVendorItems = args => [
    <Link title={t('base._client_logged_in_header.home')} to={ROUTE.vendorDashboard} key="vendor_dashboard" topLevel />,
    getVendorReportsDropdown(args),
    getVendorSetUpDropdown(args),
    <Link title="SPIFF" to={ROUTE.spiff.applicationsRoot} key="spiff" visible={args.modules[MODULES.SPIFF]} topLevel />,
];

const getMarketingStoreAndCampaignsDropdown = args => {
    const { modules, marketingStoreSettings } = args;
    if (!modules[MODULES.MARKETING_STORE]) {
        return null;
    }
    const areCampaignsEnabled = modules[MODULES.CAMPAIGN_BUILDER] || marketingStoreSettings.campaignsEnabled;
    const dropdownLinkTitle = getDropdownLinkTitle(args);
    const assetLibrary = modules[MODULES.ASSET_LIBRARY];

    return (
        <DropDownLink title={dropdownLinkTitle} key="VendorSetUpDropdown">
            <DropDownSection
                title={t('client._general_dropdown.section_title.managed_marketing_activities')}
                description={t('client._general_dropdown.section_description.managed_marketing_activities_4')}
            >
                <Separator />
                <Link title={t('client._campaigns_dropdown.find_assets')} to={ROUTE.marketingStore.root} />
                <Link
                    visible={areCampaignsEnabled}
                    title={t('client._campaigns_dropdown.create_campaign')}
                    to={ROUTE.createCampaign.root}
                />
            </DropDownSection>
            <DropDownSection
                title={t('client._general_dropdown.section_title.resources')}
                description={t('client._general_dropdown.section_description.resources_3')}
                visible={assetLibrary}
            >
                <Separator />
                <Link
                    title={t('client._vendor_reports_dropdown.view_all_resources')}
                    to={ROUTE.assetLibrary.browse.list}
                />
            </DropDownSection>
        </DropDownLink>
    );
};

/**
 * Helper function for Menu
 * @ignore
 */
export const getConciergePartnerItems = args => [
    getPartnerDashboardItems(),
    getMarketingStoreAndCampaignsDropdown(args),
    getActivityReportsDropdownItems(args),
];

/**
 * Helper function for Menu
 * @ignore
 */
export const getCustomPagesItems = pages =>
    pages.map(page => <Link title={page.name} to={ROUTE.customPage(page.slug)} key={page.slug} topLevel />);
